.login-form {
    position: relative;
    display: flex;
    justify-content: center;

    &-container {
        max-width: 400px;
        margin: 15px;
        padding: 15px;
        border: #cacaca solid 1px;      
        
        &.is-faded {
            opacity: 0.4;
        }
    }

    &-separator {
        margin: 15px;
    }
}