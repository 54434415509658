* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}


.App {
  padding: 30px;
  color: #214121;
  box-sizing: border-box;
}