.message {
    position: fixed;
    right: 15px;
    top: 15px;
    width: 300px;
    padding: 18px 22px;
    z-index: 1000;
    background-color: rgba(255, 255, 255, 0.9);
    box-shadow: orange 0 0 2px 1px;
    color: orange;
    border-radius: 15px;
    opacity: 0;
    transform: translateY(-50px);
    transition: all 0.3s;

    &-close {
        position: absolute;
        right: 6px;
        top: 2px;
        cursor: pointer;
    }

    svg {
        height: 15px;
        width: 15px;

        path {
            stroke: orange;
        }
    }

    &--error {
        color: red;
        box-shadow: red 0 0 2px 1px;

        svg {
            path {
                stroke: red;
            }
        }
    }

    &--success {
        color: green;
        box-shadow: yellowgreen 0 0 2px 1px;

        svg {
            path {
                stroke: green;
            }
        }
    }


    &.is-visible {
        opacity: 1;
        transform: translateY(0);
    }
}