.lds-dual-ring {
    display: inline-block;
    width: 60px;
    height: 60px;
    position: absolute;
    top: calc(50% - 30px);
    left: calc(50% - 30px);
}

.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 34px;
    height: 34px;
    margin: 8px;
    border-radius: 50%;
    border: 4px solid #787;
    border-color: #787 transparent #787 transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}