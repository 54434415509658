.todos {
    padding-top: 30px;
    display: flex;
    justify-content: space-between;

    &-title {
        color: #416141;
    }

    &-content {
        width: calc(65% - 17px);
        position: relative;
    }

    &-tools {
        width: calc(35% - 17px);
        height: calc(100vh - 300px);
        margin-top: 92px;
        padding: 15px;
        box-shadow: #619161 0 0 2px;
        border-radius: 4px;
    }
}