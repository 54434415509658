.todo-edit {
    display: block;

    &-fields {
        margin-bottom: 30px;
    }

    &-actions {
        display: flex;

        .button {
            margin-right: 15px;
        }
    }
}