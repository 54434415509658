.products {
    padding-top: 30px;
    display: flex;
    justify-content: space-between;

    &-title {
        color: #416141;
    }

    &-content {
        width: calc(65% - 17px);
        position: relative;
    }

    &-tools {
        width: calc(35% - 17px);
        height: calc(100vh - 300px);
        margin-top: 92px;
        padding: 15px;
        box-shadow: #619161 0 0 2px;
        border-radius: 4px;

        &-input {
            display: flex;
            align-items: baseline;
        }
    }

    @media all and (max-width: 900px) {
        padding-top: 0;
        flex-direction: column-reverse;

        &-title {
            font-size: 24px;
        }

        &-content {
            width: auto;
        }

        &-tools {
            height: auto;
            width: auto;
            margin-top: 10px;
            box-shadow: none;
            padding: 0;

            &-title {
                display: none;
            }
        }
    }
}