.dialog-details {
    background: rgba(0, 0, 0, 0.2);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.15s;

    &-close {
        position: absolute;
        top: 6px;
        right: 6px;
        cursor: pointer;
    }

    &-content {
        position: absolute;
        top: calc(50% - 235px);
        left: calc(50% - 355px);
        background-color: #fefefe;
        margin: 15px;
        padding: 25px 20px 15px;
        width: 700px;
        height: 350px;
        border-radius: 4px;

        &-title {
            font-size: 15pt;
        }

        &-description {
            font-size: 12pt;
            margin: 20px 0;
        }

        &-deadline {
            font-size: 11pt;
        }
    }

    &.is-visible {
        opacity: 1;
    }
}