.layout {
    &-menu {
        &-list {
            list-style-type: none;
            padding: 0;
            margin: 20px 0 40px;
            display: flex;

            &-item {
                cursor: pointer;
                margin-right: 20px;
                padding: 10px 20px;
                border: #dedede solid 1px;
                border-radius: 4px;

                &.is-active {
                    background-color: #eefeee;
                }

                &.is-disabled {
                    opacity: 0.3;
                    pointer-events: none;
                }
            }
        }
    }

    &-container {
        position: relative;
    }

    &-tab {
        position: absolute;
        width: 100%;
        opacity: 0;
        transition: opacity .15s;
        pointer-events: none;

        &.is-active {
            opacity: 1;
            pointer-events: all;
        }
    }

    @media all and (max-width: 900px) {
        &-menu {
            &-list {
                margin: 0;

                &-item {
                    margin-bottom: 8px;
                    margin-right: 10px;
                    padding: 4px;
                    font-size: 12px;
                }
            }
        }
    }
}