.button {
    position: relative;
    padding: 15px 30px;
    font-size: 12pt;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;

    &-dark {
        background-color: #619161;
        color: #fefefe;

        &:hover {
            background-color: #71A171;
        }
    }

    &-bright {
        background-color: #fefefe;
        border: #619161 solid 2px;
        color: #619161;
    }
}