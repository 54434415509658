.input-default {
    position: relative;
    padding-top: 20px;
    font-size: 11pt;
    font-weight: 300;
    text-align: left;
    width: 100%;

    &--half {
        width: calc(50% - 8px);
    }

    &-message {
        position: absolute;
        font-size: 8pt;
        font-weight: 500;
        color: #ff5500;
        top: 6px;
        left: 2px;
    }

    label {
        position: absolute;
        top: 19px;
        left: 3px;
        font-size: 8pt;
        transform-origin: 0;
        transition: transform 0.2s;
    }

    input {
        width: 100%;
        height: 45px;
        font-size: 13pt;
        font-weight: 300;
        padding: 0 10px;
        border-color: transparent;
        border-radius: 5px;
        box-shadow: #619161 0 0 2px;
        font-family: inherit;
    }

    textarea {
        width: 100%;
        font-size: 13pt;
        font-weight: 300;
        padding: 10px;
        border-color: transparent;
        border-radius: 10px;
        box-shadow: #619161 0 0 2px;
        resize: none;
        height: 120px;
        font-family: inherit;
    }

    &.is-error {
        input,
        textarea {
            border-color: #ff5500;
        }
    }
}