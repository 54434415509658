.todo-item {
    display: flex;
    justify-content: space-between;
    box-shadow: #619161 0 0 2px;
    padding: 15px;
    margin: 10px 0;
    border-radius: 4px;
    transition: background-color 0.2s;

    &-title {
        width: calc(100% - 170px);
    }

    &-deadline {
        padding-top: 4px;
        font-size: 9pt;
        margin-right: 20px;
    }

    &-action {
        button {
            background: none;
            border: none;
            cursor: pointer;
            margin: 0 3px;
            transition: transform 0.1s;

            &:hover {
                transform: scale(1.15);
            }
        }
    }

    &:hover {
        background-color: #efefef;
    }
}